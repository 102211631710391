export default async (pageType: string) => {
  switch (pageType) {
    case 'InvestorRelationsStartPage':
    case 'InvestorRelationsFullWidthPage':
    case 'InvestorRelationsStandardPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "investors" */
            '@pages/InvestorRelations'
          ),
          selector: 'ir-root',
        },
      ];

    case 'NewsListPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "newsList" */
            '@pages/NewsList'
          ),
          selector: 'news-list',
        },
      ];
    case 'NewsListingPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "newsAndStories" */
            '@pages/NewsAndStories'
          ),
          selector: 'news-listing',
        },
      ];
    case 'SearchPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "search" */
            '@pages/SearchPage'
          ),
          selector: 'SearchPage',
        },
      ];

    case 'CustomerStoryListPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "stories" */
            '@pages/Stories'
          ),
          selector: 'customer-story-list-page',
        },
      ];

    case 'PageIndustry':
    case 'PageProductConstruction':
    case 'PageProductLine':
      return [
        {
          component: await import(
            /* webpackChunkName: "related" */
            '@layouts/Related'
          ),
          selector: 'RelatedTabs',
        },
        {
          component: await import(
            /* webpackChunkName: "contactUsBlock" */
            '@modules/EnquiryForm'
          ),
          selector: 'ContactUsBlock',
        },
      ];

    // Added to render RFQ, RFS reusable block for some of the page types
    case 'StandardPage':
    case 'ServicePage':
    case 'ServicesListPage':
    case 'NewsArticlePage':
    case 'CustomerStoryPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "contactUsBlock" */
            '@modules/EnquiryForm'
          ),
          selector: 'ContactUsBlock',
        },
      ];

    case 'ContactUsPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "contactUs" */
            '@pages/ContactUs'
          ),
          selector: 'ContactUsPage',
        },
      ];
    case 'EnquiryTypePage':
      return [
        {
          component: await import(
            /* webpackChunkName: "enquiry" */
            '@pages/Enquiry'
          ),
          selector: 'EnquiryPage',
        },
      ];
    case 'SafetyDataSheetPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "dataSheetPage" */
            '@pages/SafetyDatasheet'
          ),
          selector: 'SafetyDataSheetPage',
        },
      ];
    case 'WebinarLandingPage':
      return [
        {
          component: await import(
            /* webpackChunkName: "webinarLanding" */
            '@pages/Webinars'
          ),
          selector: 'WebinarLanding',
        },
      ];

    default:
      return await null;
  }
};
